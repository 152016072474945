import AppBase, {
  createNavigateEvent,
} from '@yoummday/ymmd-platform-core/comp/app-shell';
import { header } from './header.js';
import { aside } from './aside.js';
import { customElement, query, state } from 'lit/decorators.js';
import { render, html, nothing, css, unsafeCSS } from 'lit';
import { when } from 'lit/directives/when.js';
import { User } from '../../modules/User';
import { classMap } from 'lit/directives/class-map.js';
import { socket } from '@yoummday/ymmd-websocket';
import type { PushMsg } from '@yoummday/ymmd-websocket/dist/types';
import { i18n } from '../../i18n';
import Template from '../../modules/Template.js';
import { Storage } from '@yoummday/ymmd-client-storage';
import { br, notification } from '@yoummday/ymmd-platform-core/utils';
import { Log } from '@yoummday/ymmd-logger';
import { legacyAPI } from '../../modules/api';
import { Router } from '../../modules/router';
import type { Page } from '../page-base';
import type { YmdRoute } from '@yoummday/ymmd-router/dist/types';
import '@yoummday/ymmd-platform-core/comp/main-player';
import styles from './style/index.scss?inline';
import platformBackground from './assets/platform-background.svg?no-inline';

@customElement('app-onboarding')
export default class AppOnboarding extends AppBase {
  static styles = [
    styles,
    css`
      :host {
        background-image: ${unsafeCSS(`url(${platformBackground})`)};
      }
    `,
  ];
  view?: Page | Template;
  // deprecated: legacy container is for Template Class Instance
  @query('#legacy-page') legacyContainer!: HTMLElement;

  @state() route?: YmdRoute;

  localStorage = new Storage(this.nameSpace, 'local');

  connectedCallback() {
    this.addEventListeners();
    super.connectedCallback();
    Router.subscribe(async (route, params, queryParams) => {
      if (!route.routeDefaultExport) return;
      if (this.view instanceof Template) this.view.destroy();
      if (route.routeDefaultExport.isLegacy) {
        this.view = new route.routeDefaultExport(
          // eslint-disable-next-line no-undefined
          undefined,
          params || {},
          queryParams || {},
          route,
        ) as Template;
        if (!this.view) return;

        render(nothing, this);
        render(this.view.container, this.legacyContainer);
        await this.view.init();
      } else {
        this.view = new route.routeDefaultExport();
        if (!this.view) return;
        this.view.params = params || {};
        this.view.query = queryParams || {};
        this.view.route = route;
        render(nothing, this.legacyContainer);
        render(this.view, this);
      }
      this.route = route;
    });
  }

  addEventListeners() {
    socket.Log = Log;
    socket.subscribe(this, 'push', ({ detail: { msgs = [] } }) => {
      if (
        !msgs.some(
          ({ answer, id }) =>
            !answer && // not answered yet
            !(this.localStorage.get('seenPushes') || []).includes(id), // not yet seen
        )
      ) {
        return;
      }
      const singlePush = (push: PushMsg) => {
        const container = document.createElement('div');
        render(
          html`
            <div style="display:flex; align-items:center;">
              <div>
                <user-thumb
                  .user=${push.user || { ymdItself: true }}
                  size="40"
                ></user-thumb>
              </div>
              <div>
                <h5 class="m-0">${push.title}</h5>
              </div>
            </div>
            <p>${br(push.text)}</p>
          `,
          container,
        );
        return container;
      };
      notification(window.T.message.event.pushmsg, '', i18n.language);
      msgs.forEach((push) => {
        const container = singlePush(push);
        this.onShowToast(
          new CustomEvent('showToast', {
            detail: {
              element: container,
              variant: 'neutral',
              onDismissed: () => {
                this.localStorage.push('seenPushes', push.id);
              },
            },
          }),
        );
      });
    });
  }

  onLogout({ detail: onPurpose }: CustomEvent<boolean>) {
    delete User.user;
    Router.navigate('/login', {
      updateBrowserURL: false,
    });
    socket.kill();
    sessionStorage.clear();
    if (!onPurpose) {
      this.showSnackBar({
        str: window.T.error.auth,
        variant: 'danger',
      });
    } else {
      legacyAPI.POST('/logout');
    }
  }

  onNavigate(e: ReturnType<typeof createNavigateEvent>) {
    Router.navigate(e.detail);
  }
  // just a bridge for legacy Model-Class
  userUpdated(user: APIuser) {
    User.updateUser(user);
  }

  render() {
    return html`
      <article class=${classMap({ 'has-header': !!this.route?.hasHeader })}>
        ${when(
          User.hasSession && this.route?.hasAsideNavigation,
          () => html` <aside>${aside()}</aside> `,
        )}

        <main>
          <main-player>
            <section
              class=${classMap({
                'view-container': true,
                'no-padding':
                  !this.route?.hasHeader && !this.route?.hasAsideNavigation,
              })}
            >
              ${when(
                this.isLoading,
                () => html` <div class="loading-bar"></div> `,
              )}
              <slot></slot>
              <div id="legacy-page"></div>
            </section>
          </main-player>
        </main>
      </article>

      ${when(
        this.route?.hasHeader && User.hasSession,
        () => html` <header>${header()}</header> `,
      )}
      ${when(this.dialog, () => this.renderDialog())}
      <!---->
      ${this.renderSnackBar()}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'app-onboarding': AppOnboarding;
  }
}
