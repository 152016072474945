/* eslint-disable camelcase */
import { User } from './User';
import { Log } from '@yoummday/ymmd-logger';

const isProduction = import.meta.env.VITE_SENTRY_ON === '1';

interface ConsentLevels {
  functionality?: boolean;
  'strictly-necessary'?: boolean;
  tracking?: boolean;
  targeting?: boolean;
}

interface EventActions {
  user: 'userInteraction';
  ui: 'uiTriggered';
  page: 'trackPageView';
}

export const readCookieByName = (cookieName: string): string =>
  document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`)?.pop() || '';

const canTrack = (): boolean => {
  if (
    window.UC_UI?.isInitialized?.() &&
    window._mtm &&
    window._paq &&
    isProduction
  ) {
    return true; //tracking and tag managnement is handeled by the tag manager itself
  }

  const levelCookie = readCookieByName('cookie_consent_level') || '';
  if (
    levelCookie === '' ||
    !window._paq ||
    !window._mtm ||
    !isProduction // only track on prod system
  ) {
    return false;
  }

  try {
    return (
      (JSON.parse(decodeURIComponent(levelCookie)) as ConsentLevels).tracking ||
      false
    );
  } catch (error: unknown) {
    Log.error(error as Error);
    return false;
  }
};

const userId = () => User.user?.id || null;

const isAlreadyTracked = ({
  dataLayer,
  eventName,
}: {
  dataLayer: Window['_mtm'];
  eventName: string;
}): boolean =>
  dataLayer.some(
    (mtmEvent) =>
      'event_data' in mtmEvent && mtmEvent.event_data?.name === eventName,
  );

export const matomoTracker = {
  init(src: string) {
    window._paq = window._paq || [];
    window._mtm = window._mtm || [];
    window._mtm.push({
      'mtm.startTime': new Date().getTime(),
      event: 'mtm.Start',
    });
    const d = document;
    const g = d.createElement('script');
    const [s] = d.getElementsByTagName('script');
    g.async = true;
    g.src = src;
    s.parentNode!.insertBefore(g, s);
  },

  eventTrackForeignUser(userid = '', ...trackingParams: Array<string>) {
    if (!canTrack() || !userid) return;
    window._paq.push(['setCustomUrl', window.location.pathname]);
    window._paq.push(['setUserId', userid]);
    window._paq.push(['trackEvent', ...trackingParams]);
  },

  track({
    trigger = 'user',
    name,
    once = false,
    options = {},
  }: {
    trigger?: keyof EventActions;
    name: string;
    once?: boolean;
    options?: object;
  }) {
    const { _mtm } = window;
    if (
      !canTrack() ||
      (once && isAlreadyTracked({ dataLayer: _mtm, eventName: name }))
    ) {
      return;
    }

    if (!_mtm.some((mtmEvent) => 'uid' in mtmEvent)) {
      _mtm.push({ uid: userId() });
    }

    const eventValues: EventActions = {
      user: 'userInteraction',
      ui: 'uiTriggered',
      page: 'trackPageView',
    };

    _mtm.push({
      event: 'event-to-tag',
      event_data: {
        name,
        action: eventValues[trigger],
        options: { ...options, trackOnce: once },
      },
    });
  },

  pageTrack() {
    if (!canTrack()) return;
    setTimeout(() => {
      if (window._svc && User.hasSession) {
        window._sva?.setVisitorTraits({
          user_id: 'yoummday', // this should not be a real userId!
        });
      }
      window._paq.push(['setCustomUrl', window.location.pathname]);
      window._paq.push(['setUserId', userId()]);

      window._paq.push(['trackPageView', document.title]);

      window._mtm.push({
        event: 'trackPageView',
        customUrl: window.location.pathname,
      });
    }, 100);
  },
};

type MtmPayloadDefault = Array<string | null>;
type MtmPayloadAdvanced = {
  event_data?: {
    name?: string;
    action: 'userInteraction' | 'uiTriggered' | 'trackPageView';
    options: { trackOnce: boolean } & object;
  };
  uid?: string | null;
  'mtm.startTime'?: string | number;
  event?: string;
  customUrl?: string;
  'event-to-advertiser'?: string;
};
declare global {
  interface Window {
    _mtm: Array<MtmPayloadDefault | MtmPayloadAdvanced>;
    _paq: Array<(string | null)[] | object>;
    UC_UI?: { isInitialized?: () => boolean };
    _svc: object;
    _sva: {
      setVisitorTraits: (arg0: { user_id: string }) => void;
    };
  }
}
