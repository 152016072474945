import { User as BaseUser } from '@yoummday/ymmd-platform-core';

class OnboardingUser extends BaseUser {
  get spokenLangs(): string[][] {
    return (
      this.user?.spokenLangs
        ?.sort()
        .map((l) => l.split(':'))
        .filter((l) => !/\d/u.test(l[0])) || []
    );
  }
}

export const User = new OnboardingUser();
