import moment from 'moment-timezone';

const clocks: HTMLSpanElement[] = [];
const currentClockTime = (tz: string): string => moment.tz(tz).format('dd LT');

setInterval(() => {
  clocks.forEach((clock, i) => {
    if (!clock.isConnected) {
      clocks.splice(i, 1);
      return;
    }
    const newTime = currentClockTime(clock.dataset.timezone || 'UTC');
    if (clock.innerText !== newTime) clock.innerText = newTime;
  });
}, 1000);

export const clock = (tz: string = ''): HTMLSpanElement => {
  const clockContainer = document.createElement('span');
  clockContainer.dataset.timezone =
    tz || Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
  clockContainer.innerText = currentClockTime(clockContainer.dataset.timezone);
  clocks.push(clockContainer);
  return clockContainer;
};
