import type { YmdRoute } from '@yoummday/ymmd-router/dist/types';

export default <Record<string, YmdRoute>>{
  '/talent/:talentId/:settingsgroup': {
    as: 'talent',
    module: () => import('./talent.js'),
  },
  '/talent/:talentId': {
    as: 'talent',
    module: () => import('./talent.js'),
  },
  '/webcamsessions': {
    as: 'webcamsessions',
    module: () => import('./webcamsessions.js'),
  },
  '/verification': {
    as: 'verification',
    module: () => import('./verification.js'),
  },
  '/schedule': {
    as: 'schedule',
    module: () => import('./schedule.js'),
  },
  '/supporttickets': {
    as: 'supporttickets',
    module: () => import('./supporttickets.js'),
  },
};
