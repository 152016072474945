import { User } from '../../modules/User.ts';
import { html } from 'lit';
import { when } from 'lit/directives/when.js';
import { matomoTracker } from '../../modules/matomoTracker.ts';
import { Router } from '../../modules/router.ts';

// eslint-disable-next-line max-lines-per-function
const renderNav = () => {
  const { url } = Router.getCurrentLocation();
  const setActiveIf = (path) => {
    if (path) {
      if (url === path || url.startsWith(`${path}/`)) {
        return 'active';
      }
      return '';
    }
    if (!url) {
      return 'active';
    }
    return '';
  };

  const prismicLinks = {
    news: 'https://yoummday2.prismic.io/documents/working?k=news&l=en-us',
    insight:
      'https://yoummday2.prismic.io/documents/working?k=insights&l=en-us',
    worthknowing:
      'https://yoummday2.prismic.io/documents/working?k=worth_knowings&l=en-us',
    faq: 'https://yoummday2.prismic.io/documents/working?k=faq_group&l=en-us',
  };

  const eventNames = {
    talents: 'ob.nav.main.talents',
    verification: 'ob.nav.main.verification',
    pictureVerification: 'ob.nav.main.pictureVerification',
    appointments: 'ob.nav.main.appointments',
    webcamSessions: 'ob.nav.main.webcamSessions',
    supportRequests: 'ob.nav.main.supportRequests',
    newsfeed: 'ob.nav.main.newsfeed',
    wissenswertes: 'ob.nav.main.wissenswertes',
    insightsAndWebinar: 'ob.nav.main.insightsAndWebinar',
    faq: 'ob.nav.main.faq',
    assetManagement: 'ob.nav.main.assetManagement',
  };

  return html`
    <nav slot="content">
      <app-link
        href="/"
        class=${setActiveIf()}
        @click=${() => matomoTracker.track({ name: eventNames.talents })}
      >
        <span>
          <iconify-icon icon="mdi-account" class="iconify"></iconify-icon>
        </span>
        Talents
      </app-link>
      <app-link
        href="verification"
        class=${setActiveIf('verification')}
        @click=${() => matomoTracker.track({ name: eventNames.verification })}
      >
        <span>
          <iconify-icon icon="mdi-check-circle" class="iconify"></iconify-icon>
        </span>
        Verification
      </app-link>
      <app-link
        href="avatarverification"
        class=${setActiveIf('avatarverification')}
        @click=${() =>
          matomoTracker.track({ name: eventNames.pictureVerification })}
      >
        <span>
          <iconify-icon
            icon="mdi-account-box-multiple"
            class="iconify"
          ></iconify-icon>
        </span>
        Picture Verification
      </app-link>
      <app-link
        href="schedule"
        class=${setActiveIf('schedule')}
        @click=${() => matomoTracker.track({ name: eventNames.appointments })}
      >
        <span>
          <iconify-icon icon="mdi-calendar" class="iconify"></iconify-icon>
        </span>
        Appointments
      </app-link>
      <app-link
        href="webcamsessions"
        class=${setActiveIf('webcamsessions')}
        @click=${() => matomoTracker.track({ name: eventNames.webcamSessions })}
      >
        <span>
          <iconify-icon icon="mdi-webcam" class="iconify"></iconify-icon>
        </span>
        Webcam Sessions
      </app-link>
      <app-link
        href="supporttickets"
        class=${setActiveIf('supporttickets')}
        @click=${() =>
          matomoTracker.track({ name: eventNames.supportRequests })}
      >
        <span>
          <iconify-icon
            icon="mdi-chat-alert-outline"
            class="iconify"
          ></iconify-icon>
        </span>
        Support Requests
      </app-link>
      ${User.can('newsfeed')
        ? html`
            <hr />
            <a
              href=${prismicLinks.news}
              class=${setActiveIf('newsfeed/news')}
              target="_blank"
              @click=${() => matomoTracker.track({ name: eventNames.newsfeed })}
            >
              <span>
                <iconify-icon icon="mdi-rss" class="iconify"></iconify-icon>
              </span>
              Newsfeed
            </a>
            <a
              href=${prismicLinks.worthknowing}
              class=${setActiveIf('worthknowing')}
              target="_blank"
              @click=${() =>
                matomoTracker.track({ name: eventNames.wissenswertes })}
            >
              <span>
                <iconify-icon
                  icon="mdi:head-snowflake-outline"
                  class="iconify"
                ></iconify-icon>
              </span>
              Wissenswertes
            </a>
            <a
              href=${prismicLinks.insight}
              class=${setActiveIf('newsfeed/insights')}
              target="_blank"
              @click=${() =>
                matomoTracker.track({ name: eventNames.insightsAndWebinar })}
            >
              <span>
                <iconify-icon
                  icon="mdi:chat-question-outline"
                  class="iconify"
                ></iconify-icon>
              </span>
              Insights & Webinar
            </a>

            <a
              href=${prismicLinks.faq}
              class=${setActiveIf('faq')}
              target="_blank"
              @click=${() => matomoTracker.track({ name: eventNames.faq })}
            >
              <span>
                <iconify-icon
                  icon="mdi:frequently-asked-questions"
                  class="iconify"
                ></iconify-icon>
              </span>
              FAQ
            </a>
          `
        : ''}
      ${User.can('assetManagement')
        ? html`
            <hr />
            <app-link
              href="assetmanager"
              class=${setActiveIf('assetmanager')}
              @click=${() =>
                matomoTracker.track({ name: eventNames.assetManagement })}
            >
              <span>
                <iconify-icon
                  icon="mdi:cellphone-link"
                  class="iconify"
                ></iconify-icon>
              </span>
              Asset Management
            </app-link>
          `
        : ''}
    </nav>
  `;
};

export const aside = () =>
  when(User.can('onboarding') || User.can('onboardingReadonly'), () =>
    when(
      User.device.type === 'mobile',
      () => html`
        <yd-dropdown .direction=${'top-left'}>
          <div slot="toggle" class="burgerbutton">
            <div><span></span></div>
          </div>
          ${renderNav()}
        </yd-dropdown>
      `,
      () => renderNav(),
    ),
  );
