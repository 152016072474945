import YmmdRouter from '@yoummday/ymmd-router';
import type { YmdRoute } from '@yoummday/ymmd-router/dist/types';
import { User } from './User';
import { Log } from '@yoummday/ymmd-logger';
import { matomoTracker } from './matomoTracker';
import { socket } from '@yoummday/ymmd-websocket';

export const Router = new YmmdRouter(import.meta.env.BASE_URL);

Router.notFound(() => {
  if (User.hasSession) {
    Router.navigate('/notfound', {
      updateBrowserURL: false,
    });
    const { url } = Router.getCurrentLocation();
    Log.error(new Error(`Not found: ${url}`));
  } else {
    Router.navigate('/login', {
      updateBrowserURL: false,
    });
  }
});

const sessionOk = (ymdRoute: YmdRoute) => {
  if (ymdRoute.isAuthRequired && !User.hasSession) {
    Router.navigate('/login', {
      updateBrowserURL: false,
    });
    return false;
  }

  return true;
};

Router.declareDefaultRouteConfig({
  hooks: {
    before: (canContinueNavigation, match, ymdRoute) => {
      canContinueNavigation(sessionOk(ymdRoute));
    },
    after: (match, ymdRoute) => {
      if (ymdRoute.hasPageTracking) matomoTracker.pageTrack();
      socket.initAndAuthorize(
        User.user?.websocket,
        import.meta.env.VITE_YOUMMDAY_SOCKET,
      );
    },
  },
});

const routeFiles: Record<string, YmmdRouter['ymdRoutes']> = import.meta.glob(
  ['../**/_routes.ts'],
  {
    eager: true,
    import: 'default',
  },
);

Object.values(routeFiles).forEach((routes) => {
  Router.registerRoutes(routes);
});
