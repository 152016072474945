import { User } from '../../modules/User.ts';
import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { clock } from '../../utils/index.ts';
import { createLogoutEvent } from '@yoummday/ymmd-platform-core/comp/app-shell';
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js';

export const header = () => html`
  <div class="row justify-content-between align-items-center">
    <app-link href="/" class="logo col-auto">
      <iconify-icon icon="ymmd-logo" width="115"></iconify-icon>
    </app-link>
    <div class="col-auto">
      <span class="me-1">${clock(User.user.timezone)}</span>
      <span class="me-3">${User.user.timezone.split('/').reverse()[0]}</span>
      <yd-dropdown .direction=${'bottom-right'} class="d-inline-block">
        <div slot="toggle">
          <sl-avatar .image=${ifDefined(User.user.imgSmall)}></sl-avatar>
        </div>
        <div slot="content">
          <yd-card
            hasBorder
            hasShadow
            class="rounded-0 mt-2 p-2"
            style="max-width:300px"
          >
            <nav>
              <a
                href="#"
                @click=${(e) => {
                  e.preventDefault();
                  e.currentTarget.dispatchEvent(createLogoutEvent(true));
                }}
              >
                <span>
                  <iconify-icon
                    icon="mdi-logout"
                    class="iconify"
                  ></iconify-icon>
                </span>
                ${window.T.nav.logout}
              </a>
            </nav>
          </yd-card>
        </div>
      </yd-dropdown>
    </div>
  </div>
`;
